import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';
import { cmsContentExists } from '../helpers/cms-content-exists';

const VisitUs = ({ data }) => {
  const { visitus } = data;
  return (
    <Layout
      bgcolor={visitus.backgroundColor}
      wrapperClass="visit-us"
      accentColor={visitus.accentColor}
    >
      <HelmetDatoCms seo={visitus.seoMetaTags} />
      <section className="visit-us-intro container">
        <h1 className="gothic--large large-title col-sm-7 offset-sm-2 col">{visitus.title}</h1>
        <div className="visit-us-image col-sm-7 offset-sm-2 col">
          <Img
            fluid={{ ...visitus.headerImage.fluid }}
            alt={visitus.headerImage.alt}
          />
        </div>
        <div
          className="visit-us-description col-sm-7 offset-sm-2 col"
            // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: visitus.description,
          }}
        />
      </section>
      <div className="visit-us-locations container">
        {visitus.locations.map(({
          description, details, note, name, id, image,
        }) => (
          <section aria-labelledby={id} className="visit-us-location" key={id}>
            <h2 id={id} className="gothic--large large-title col">{name}</h2>
            <div className="location-image col">
              <Img fluid={image.fluid} alt={image.alt} />
            </div>
            <div className="location-details col">
              <div
                className="details content"
              // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: details,
                }}
              />
              <div className="details-divider">
                <DividerBlock accentColor={visitus.accentColor} />
              </div>
            </div>
            <div
              className="location-text content col"
            // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
            {cmsContentExists(note) && (
            <div
              className="location-note content sans--small col"
              dangerouslySetInnerHTML={{
                __html: note,
              }}
            />
            )}
          </section>
        ))}
      </div>
      <div className="divider container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={visitus.accentColor} />
        </div>
      </div>
    </Layout>
  );
};

VisitUs.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default VisitUs;

export const query = graphql`
  query VisitUsQuery {
    visitus: datoCmsVisit {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      backgroundColor
      accentColor {
        hex
      }
      description
      title
      headerImage {
        alt
        fluid(maxHeight: 600) {
          ...GatsbyDatoCmsSizes_noBase64
        }
      }
      locations {
        description
        details
        note
        name
        id
        image {
          alt
          fluid(maxHeight: 540) {
            ...GatsbyDatoCmsSizes_noBase64
          }
        }
      }
    }
  }
`;
